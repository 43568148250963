.card {
  display: block;
  overflow: hidden;
  text-decoration: none;
}

.card-information + .card {
  margin-bottom: 1.3rem;
}

@media screen and (min-width: 750px) {
  .card-information + .card {
    margin-bottom: 1.7rem;
  }
}

.card.card--soft {
  background-color: rgba(var(--color-foreground), 0.04);
  color: rgb(var(--color-foreground));
}

.card .icon-wrap {
  margin-left: 0.8rem;
  white-space: nowrap;
  transition: transform var(--duration-short) ease;
  overflow: hidden;
}

.card--media {
  display: block;
  text-decoration: none;
}

.card--text-only {
  display: flex;
  justify-content: center;
}

.card--text-only.card--product {
  position: static;
}

.card--text-only::before {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.card--product {
  position: relative;
}

.card--stretch {
  height: 100%;
}

.card--outline:not(.card--soft) {
  border: calc(0.1rem / var(--font-body-scale)) solid rgba(var(--color-foreground), 0.04);
}

.card--light-border {
  border: 0.1rem solid rgba(var(--color-foreground), 0.04);
}

.card--light-border:hover {
  border: 0.1rem solid rgba(var(--color-foreground), 0.3);
  box-shadow: none;
}

.card__text-spacing {
  padding: 3rem;
}

.card-colored.color-background-1 {
  background-color: rgba(var(--color-foreground), 0.04);
}

.card--media .card__text-spacing {
  padding: 2rem;
}

@media screen and (min-width: 750px) {
  .card--media .card__text-spacing {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.card-information > * + * {
  margin-top: 0.5rem;
}

.card--text-only .card__inner {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
  width: 100%;
}

.card__content {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.card__content > * {
  margin: 0;
}

.card--text-only .card__content {
  grid-row: 2;
  justify-self: flex-start;
  margin-bottom: 6rem;
  margin-top: 5rem;
}

.card--text-only .card__badge {
  align-self: flex-end;
  grid-row: 3;
}

.card--search .card__badge > *,
.card--text-only .card__badge > * {
  margin: 0 1.2rem 1.2rem;
}

.card--search .card__badge,
.card--text-only .card__badge {
  position: initial;
}

.card--text-only .card__content + .card__badge {
  margin-top: -5rem;
}

.media + .card__content {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 750px) {
  .card--text-only .card__content {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .card--text-only .card__content + .card__badge {
    margin-top: -7rem;
  }
}

.card__text-spacing > * {
  margin: 0;
}

.card__text-spacing > *:not(.overlay-card) + * {
  margin-top: 1.5rem;
}

.card__text {
  margin: 0;
  word-break: break-word;
}

.card-information__text {
  display: block;
  margin: 0;
  padding-right: 1.2rem;
}

.card-information__wrapper {
  width: 100%;
}

.card-information__wrapper > * {
  line-height: calc(1 + 0.4 / var(--font-body-scale));
  color: rgb(var(--color-foreground));
}

.card-information__wrapper > .price {
  color: rgb(var(--color-foreground));
}

.card-information__wrapper > .rating {
  margin-top: 0.4rem;
}

.card-information__wrapper
  > *:not(.visually-hidden:first-child)
  + *:not(.rating) {
  margin-top: 0.7rem;
}

.card-information__wrapper .caption {
  letter-spacing: 0.07rem;
}

.card-wrapper {
  color: inherit;
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
}

.card-wrapper .card-information {
  order: 1;
}

.card-wrapper .full-unstyled-link::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.card-wrapper .full-unstyled-link:focus-visible {
  outline: 0;
  box-shadow: none;
}

.card-wrapper .full-unstyled-link:focus-visible::after {
  outline: .2rem solid rgba(var(--color-foreground),.5);
  outline-offset: 0.3rem;
  box-shadow: 0 0 0 .3rem rgb(var(--color-background)),0 0 .5rem .4rem rgba(var(--color-foreground),.3);
}

.card-wrapper:focus-within .card {
  box-shadow: none;
}

.card__media-spacer {
  padding: 2rem 2rem 0;
}

@media screen and (min-width: 750px) {
  .card__media-spacer {
    padding: 3rem 3rem 0;
  }
}

.card__media-full-spacer {
  padding: 2rem;
}

.card-article-info {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
}

.card__badge {
  bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  left: 1rem;
  position: absolute;
}

.card__badge > * {
  margin-right: 1rem;
  margin-top: 0.5rem;
}

.overlay-card {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  background: transparent;
  transition: background-color var(--duration-long) ease;
}

.card:hover .card-colored .overlay-card {
  background-color: rgba(var(--color-card-hover), 0.06);
}

@media screen and (min-width: 990px) {
  .card .media.media--hover-effect > img:only-child,
  .card-wrapper .media.media--hover-effect > img:only-child,
  .card--search img {
    transition: transform var(--duration-long) ease;
  }

  .card:hover .media.media--hover-effect > img:first-child:only-child,
  .card-wrapper:hover .media.media--hover-effect > img:first-child:only-child {
    transform: scale(1.03);
  }

  .card-wrapper:hover
    .media.media--hover-effect
    > img:first-child:not(:only-child) {
    opacity: 0;
  }

  .card-wrapper:hover .media.media--hover-effect > img + img {
    opacity: 1;
    transition: transform var(--duration-long) ease;
    transform: scale(1.03);
  }

  .card-wrapper:hover .card-information__text {
    text-decoration: underline;
    text-underline-offset: 0.3rem;
  }

  .card-wrapper:hover .card--search img {
    transform: scale(1.05);
  }

  .card-wrapper:hover .card__text {
    text-decoration: underline;
    text-underline-offset: 0.3rem;
  }

  .card-wrapper:hover .card--soft {
    background-color: rgba(var(--color-card-hover), 0.06);
    transition: background-color var(--duration-long) ease;
  }
}
